<template>
    <ion-header class="mobile-tabs-footer">
        <div class="mobile-tab-wrapper"
            v-if="openedTab == 'tools' || openedTab == 'more' || openedTab == 'profile'">
            <div v-if="openedTab === 'tools'">
                <ToolsTab @close="closeModal" />
            </div>
            <div v-if="openedTab === 'more'">
                <CommunityTab @close="closeModal" />
            </div>
            <div v-if="openedTab === 'profile'">
                <ProfileTab @close="closeModal" />
            </div>
        </div>
        <ion-toolbar color="transparent">
            <div slot="start" class="footer-wrapper">
                <ion-row>
                    <ion-col v-for="tab in tabs" :key="tab.value" class="single-tool" @click="selectTab(tab)"
                        :class="{ 'active': selectedTab == tab.value || openedTab == tab.value}"
                        v-track-click="{category: 'menu', action:'select-menu-chapter', label: tab.label}">
                        <ion-icon :src="tab.icon"></ion-icon>
                        <ion-text>{{ tab.label }}</ion-text>
                    </ion-col>
                    <ion-col v-if="!authStore.isLoggedIn" class="single-tool" @click="selectTab({url: '/login'})"
                        :class="{ 'active': openedTab == 'login' || selectedTab == 'login'}"
                        v-track-click="{category: 'menu', action:'select-menu-chapter', label: 'login'}">
                        <ion-icon src="/assets/icons/mobileTabsIcons/IoPersonCircle.svg"></ion-icon>
                        <ion-text>Login</ion-text>
                    </ion-col>
                    <ion-col v-if="authStore.isLoggedIn" class="single-tool" @click="selectTab({value: 'profile'})"
                        :class="{ 'active': openedTab == 'profile' || selectedTab == 'profile'}"
                        v-track-click="{category: 'menu', action:'select-menu-chapter', label: 'profile'}">
                        <ion-icon src="/assets/icons/mobileTabsIcons/IoPersonCircle.svg"></ion-icon>
                        <ion-text>Profile</ion-text>
                    </ion-col>
                </ion-row>
            </div>
        </ion-toolbar>
    </ion-header>
</template>

<script lang="ts">
import router from "@/router";
import { useAuthStore } from "@/store/useAuth";
import { IonHeader, IonToolbar, IonRow, IonCol, IonIcon, IonText } from "@ionic/vue";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import ProfileTab from '../header/mobile/ProfileTab.vue'
import ToolsTab from '../header/mobile/ToolsTab.vue'
import CommunityTab from '../header/mobile/CommunityTab.vue'
export default defineComponent({
    components: {
        IonToolbar,
        IonHeader, IonRow, IonCol, IonIcon, IonText,
        ProfileTab, ToolsTab, CommunityTab
    },
    setup() {
        const selectedTab = ref(null);
        const openedTab = ref(null);
        const authStore = useAuthStore();
        const tabs = [
            { label: 'Home', value: 'platinum', url: '/home', icon: '/assets/icons/mobileTabsIcons/IoHome.svg' },
            { label: 'Games', value: 'live', url: '/', icon: '/assets/icons/mobileTabsIcons/IoFootball.svg' },
            { label: 'Tools', value: 'tools', icon: '/assets/icons/mobileTabsIcons/IoApps.svg' },
            { label: 'More', value: 'more', icon: '/assets/icons/mobileTabsIcons/IoMenu.svg' },
        ];

        const selectTab = (val) => {
            if (val.url) goTo(val.url);
            else {
                if (openedTab.value == val.value) {
                    openedTab.value = null;
                }
                else {
                    openedTab.value = val.value;
                }

            }
        }

        const closeModal = () => {
            openedTab.value = null;
        }

        const goTo = (url: string) => {
            router.replace(url);
            closeModal();
        }

        const handleClickOutside = (event: MouseEvent) => {
            const mobileTabWrapper = document.querySelector('.mobile-tab-wrapper');
            const mobileTabsFooter = document.querySelector('.mobile-tabs-footer');
            if (mobileTabWrapper && !mobileTabWrapper.contains(event.target as Node) &&
                mobileTabsFooter && !mobileTabsFooter.contains(event.target as Node)) {
                closeModal();
            }
        };

        const setTabByRoute = (group: string) => {
            console.log('########################################')
            console.log(group)
            console.log('########################################')
            switch(group){
                case 'home':
                    selectedTab.value = 'platinum'
                    break;
                case 'games':
                    selectedTab.value = 'live'
                    break;
                case 'articles':
                    selectedTab.value = 'more'
                    break;
                case 'auth':
                    selectedTab.value = authStore.isLoggedIn ? 'profile' : 'login'
                    break;
                case 'tools':
                    selectedTab.value = 'tools'
                    break;
                case 'more':
                    selectedTab.value = 'more'
                    break;
                default:
                    selectedTab.value = ''
                    break;
            }
        }

        onMounted(() => {
            window.addEventListener('click', handleClickOutside, true);
        });

        onUnmounted(() => {
            window.removeEventListener('click', handleClickOutside, true);
        });


        onMounted(() => {
            const current = router.currentRoute.value.meta.group;
            setTabByRoute(current.toString());

            router.afterEach((to) => {
                setTabByRoute(to.meta.group.toString());
            });
        })


        return {
            tabs,
            selectedTab,
            selectTab,
            goTo,
            authStore,
            closeModal,
            openedTab,
        }
    }
})
</script>

<style lang="sass" scoped>
.single-tool
    padding: 0
    margin: 0
    min-width: 20%
    max-width: 20%
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    color: white
    ion-text
        font-size: 12px
        margin-top: 8px
    ion-icon
        fill: #fff
        width: 24px
        height: 24px
    &.active
        color: #00AB55
        ion-icon
            fill: #00AB55

.single-tool ion-icon.beta-icon
    position: absolute
    font-size: 20px
    right: 0
    bottom: 12px
    width: 35px

.toolbar-container
    --padding: 0

.mobile-tabs-footer
    background: var(--Dark, #1A212A)
    box-shadow: 0px 5px 20px 0px rgba(22, 28, 35, 0.64)
    backdrop-filter: blur(10px)
    position: absolute
    bottom: 0
    border: none
    .mobile-tab-wrapper
        background: var(--Dark, #1A212A)
        border-top-left-radius: 16px
        border-top-right-radius: 16px
        border-top: 1px solid #00AB55

.footer-wrapper
    width: 100%
    ion-row
        margin: 0
        padding: 8px
        border-top: 1px solid #00AB55
        ion-icon
            width: 24px
            height: 24px
</style>