<template>
  <div class="detector-popup">

    <div class="popup-logo ion-text-center">
      <img src="/assets/images/sharplogo.svg" alt="Playsharper logo" />
    </div>

    <div class="ion-text-center ion-padding" v-if="step === 1">
      <h1 class="ion-no-margin ion-margin-bottom">{{ $t('adBlockerPopup.showSupportTitle') }}</h1>
      <p>{{ $t('adBlockerPopup.showSupportContent') }}</p>
      <div class="action-buttons ion-justify-content-center">
        <ion-button
          color="primary"
          class="ion-text-uppercase"
          mode="ios"
          @click="step = 2"
        >
          <ion-text color="light">
            {{ $t('adBlockerPopup.allowAds') }}
          </ion-text>
        </ion-button>
        <ion-button
          color="light"
          class="ion-text-uppercase"
          mode="ios"
          @click="showMembership"
        >
          {{ $t('adBlockerPopup.membership') }}
        </ion-button>
        <ion-button
          color="success"
          class="ion-text-uppercase"
          mode="ios"
          @click="onLogin"
          v-if="!$auth.isLoggedIn"
        >
          {{ $t('auth.login.loginBtn') }}
        </ion-button>
      </div>
      <div class="action-buttons ion-justify-content-center">
       
      </div>

    </div>
    <div class="ion-padding" v-else>
      <h1 class="ion-no-margin ion-margin-bottom ion-text-center">{{ $t('adBlockerPopup.allowAdsTitle') }}</h1>
      <ol>
        <li class="ion-margin-bottom">
          <p class="ion-no-margin">{{ $t('adBlockerPopup.allowAdsContent.first.a') }}</p>
          <p class="ion-no-margin">
            <ion-text color="gray">{{ $t('adBlockerPopup.allowAdsContent.first.b') }}</ion-text>
          </p>
        </li>
        <li class="ion-margin-bottom">
          <p class="ion-no-margin">{{ $t('adBlockerPopup.allowAdsContent.second.a') }}</p>
          <p class="ion-no-margin">
            <ion-text color="gray">{{ $t('adBlockerPopup.allowAdsContent.second.b') }}</ion-text>
          </p>
        </li>
        <li class="ion-margin-bottom">
          <p class="ion-no-margin">{{ $t('adBlockerPopup.allowAdsContent.third.a') }}</p>
        </li>
      </ol>

      <div class="action-buttons">
        <ion-button
          color="light"
          class="ion-text-uppercase"
          mode="ios"
          @click="reload"
        >
          {{ $t('adBlockerPopup.reload') }}
        </ion-button>
      </div>

    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { IonButton, IonText } from '@ionic/vue';
import router from '@/router';

export default defineComponent({
  name: 'AdBlockerPopup',
  emits: ['subscribing'],
  components: {
    IonButton,
    IonText,
  },
  setup(props, { emit }) {

    const showMembership = () => {
      emit('subscribing');
      router.replace({ name: 'subscription' });
    }

    const route = router.currentRoute.value.path;

    const step = ref(1);
    const reload = () => {
      window.location.reload();
    }

    const onLogin = () => {
      window.location.href = `/login?adblocker=true&loginpath=${route}`;
    }

    return {
      step,
      reload,
      showMembership,
      onLogin,
    }
  },
});
</script>
<style lang="sass" scoped>
.popup-logo
  padding: 20px
  img
    max-width: 130px
  
.action-buttons
  display: flex
  flex-direction: column
  

  ion-button
    font-size: 13px

h1, p, li
  color: var(--ion-color-light)

</style>