export const blockerDetected = () => {
  const ad = document.createElement('ins');
  ad.className = 'AdSense';
  ad.style.display = 'block';
  ad.style.position = 'absolute';
  ad.style.top = '-1px';
  ad.style.height = '1px';
  document.body.appendChild(ad);
  const adBlockStatus = !ad.clientHeight;
  document.body.removeChild(ad);
  return adBlockStatus;
}