<template>
    <ion-header class="s-header mobile-header">
        <ion-toolbar color="transparent">
            <div slot="start" style="width:100%;
      ">
                <ion-row class="ion-align-items-center">
                    <ion-col size="auto">
                        <ion-label class="brand-name">
                            <a :href="'/'">
                                <img src="/assets/images/sharplogo.svg" alt="logo">
                            </a>
                        </ion-label>
                    </ion-col>

                    <ion-col>
                        <ion-buttons>
                            <ion-button style="margin-left: auto;" class="notifications-toggler"
                                :id="'notifications_t_' + route" v-if="$auth?.isLoggedIn">
                                <ion-icon color="white" src="/assets/icons/notifications_filled.svg"></ion-icon>
                                <ion-badge mode="ios" v-if="authStore.notificatiosCount">{{ authStore.notificatiosCount }}</ion-badge>
                            </ion-button>
                            <notifications-widget :route="route" v-if="$auth?.isLoggedIn" />
                        </ion-buttons>
                    </ion-col>
                </ion-row>
            </div>
        </ion-toolbar>
    </ion-header>
</template>


<script lang="ts">
import { IonHeader, IonToolbar, IonButtons, IonRow, IonCol, IonLabel, IonButton, IonBadge, IonIcon } from "@ionic/vue";
import NotificationsWidget from "../../components/widgets/NotificationsWidget.vue";
import router from '@/router';
import { useAuthStore } from "@/store/useAuth";
import { defineComponent } from "vue";
export default defineComponent({
    components: {
        IonHeader, IonToolbar, NotificationsWidget, IonButtons, IonRow, IonCol, IonLabel, IonButton, IonBadge, IonIcon
    },
    setup() {
        const route = router.currentRoute.value.name?.toString() || '';
        const authStore = useAuthStore();
        return {
            route,
            authStore
        }
    }
})
</script>


<style lang="sass" scoped>
.header-logo
  height: 30px
.brand-name
  font-weight: 600
  letter-spacing: 1px
  text-transform: uppercase
  font-size: 12px
  cursor: pointer
  display: flex
  height: 45px
  align-items: center
  a
    display: block

  img 
    height: 30px

.mobile-header
    background: var(--Dark, #1A212A)
    box-shadow: 0px 5px 20px 0px rgba(22, 28, 35, 0.64)
    backdrop-filter: blur(10px)
    border: none
    ion-row
        display: flex
        width: 100%
  </style>