import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicHeader = _resolveComponent("PublicHeader")!
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_MobileMenu = _resolveComponent("MobileMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AdBlockerPopup = _resolveComponent("AdBlockerPopup")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_GoogleTagManager = _resolveComponent("GoogleTagManager")!
  const _component_Hotjar = _resolveComponent("Hotjar")!
  const _component_SchemaOrg = _resolveComponent("SchemaOrg")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (!_ctx.isMobile())
        ? (_openBlock(), _createBlock(_component_PublicHeader, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isMobile())
        ? (_openBlock(), _createBlock(_component_MobileHeader, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.isMobile())
        ? (_openBlock(), _createBlock(_component_MobileMenu, { key: 2 }))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout ? _ctx.$route.meta.layout : 'main'), null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "route",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      })),
      _createVNode(_component_ion_modal, {
        showBackdrop: true,
        animated: true,
        keyboardClose: false,
        swipeToClose: false,
        backdropDismiss: false,
        "is-open": _ctx.showAdBlockerPopup,
        cssClass: "show-support-popup",
        id: _ctx.randomId
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdBlockerPopup, { onSubscribing: _ctx.isSubscribing }, null, 8, ["onSubscribing"])
        ]),
        _: 1
      }, 8, ["is-open", "id"]),
      _createVNode(_component_GoogleTagManager),
      _createVNode(_component_Hotjar),
      _createVNode(_component_SchemaOrg)
    ]),
    _: 1
  }))
}