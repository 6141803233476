import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1121ea54"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mobile-tab-wrapper"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  slot: "start",
  class: "footer-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolsTab = _resolveComponent("ToolsTab")!
  const _component_CommunityTab = _resolveComponent("CommunityTab")!
  const _component_ProfileTab = _resolveComponent("ProfileTab")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _directive_track_click = _resolveDirective("track-click")!

  return (_openBlock(), _createBlock(_component_ion_header, { class: "mobile-tabs-footer" }, {
    default: _withCtx(() => [
      (_ctx.openedTab == 'tools' || _ctx.openedTab == 'more' || _ctx.openedTab == 'profile')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.openedTab === 'tools')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_ToolsTab, { onClose: _ctx.closeModal }, null, 8, ["onClose"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.openedTab === 'more')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_CommunityTab, { onClose: _ctx.closeModal }, null, 8, ["onClose"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.openedTab === 'profile')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_ProfileTab, { onClose: _ctx.closeModal }, null, 8, ["onClose"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_toolbar, { color: "transparent" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                    key: tab.value,
                    class: _normalizeClass(["single-tool", { 'active': _ctx.selectedTab == tab.value || _ctx.openedTab == tab.value}]),
                    onClick: ($event: any) => (_ctx.selectTab(tab))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: tab.icon
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tab.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "class"])), [
                    [_directive_track_click, {category: 'menu', action:'select-menu-chapter', label: tab.label}]
                  ])
                }), 128)),
                (!_ctx.authStore.isLoggedIn)
                  ? _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                      key: 0,
                      class: _normalizeClass(["single-tool", { 'active': _ctx.openedTab == 'login' || _ctx.selectedTab == 'login'}]),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTab({url: '/login'})))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { src: "/assets/icons/mobileTabsIcons/IoPersonCircle.svg" }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Login")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["class"])), [
                      [_directive_track_click, {category: 'menu', action:'select-menu-chapter', label: 'login'}]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.authStore.isLoggedIn)
                  ? _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                      key: 1,
                      class: _normalizeClass(["single-tool", { 'active': _ctx.openedTab == 'profile' || _ctx.selectedTab == 'profile'}]),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTab({value: 'profile'})))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { src: "/assets/icons/mobileTabsIcons/IoPersonCircle.svg" }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Profile")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["class"])), [
                      [_directive_track_click, {category: 'menu', action:'select-menu-chapter', label: 'profile'}]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}